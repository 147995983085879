import "./profile.css"

export default function Profile(){
    return (
        <img
            className="profile-image"
            src="./oysterMe.jpg"
            alt="meAndG"
         />
    )
}